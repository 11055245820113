import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   // redirect:'/',
  //   name: 'loginHome',
  //   component: () => import('../pages/home/loginHome.vue'),
  // },
  {
    path: '/',
    name: 'dashboardMain',
    component: () => import('../components/dashboardMain.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/home/loginPage.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../components/HelloWorld.vue'),
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('../pages/home/personalCenter.vue'),
  },
  {
    path: '/serviceRecord',
    name: 'serviceRecord',
    component: () => import('../pages/home/serviceRecord.vue'),
  },
  //江西
  {
    path: '/verificationCodeLogin',
    name: 'verificationCodeLogin',
    component: () => import('../pages/home/verificationCodeLogin.vue'),
  },
  {
    path: '/realPersonAuthentication',
    name: 'realPersonAuthentication',
    component: () => import('../pages/home/realPersonAuthentication.vue'),
  },
  {
    path: '/certificationSuccess',
    name: 'certificationSuccess',
    component: () => import('../pages/home/certificationSuccess.vue'),
  },
  {
    path: '/realPersonDetail',
    name: 'realPersonDetail',
    component: () => import('../pages/home/realPersonDetail.vue'),
  },
  {
    path: '/faceView',
    name: 'faceView',
    component: () => import('../pages/components/faceView.vue'),
  },
  {
    path: '/searchType',
    name: 'searchType',
    component: () => import('../pages/infoSearch/searchType.vue'),
  },
  {
    path: '/peopleSearch',
    name: 'peopleSearch',
    component: () => import('../pages/infoSearch/peopleSearch.vue'),
  },
  {
    path: '/meet-booking',
    name: 'meet-booking',
    component: () => import('../pages/meeting/meet-booking.vue'),
  },
  {
    path: '/meeting-prison',
    name: 'meeting-prison',
    component: () => import('../pages/meeting/meeting-prison.vue'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../pages/guide/to-guide.vue'),
  },
  {
    path: '/hotLine',
    name: 'hotLine',
    component: () => import('../pages/guide/hot-line.vue'),
  },

  //亲属建档
  {
    path: '/detainess-index',
    name: 'detainess-index',
    component: () => import('../pages/document/detainess-index.vue'),
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
  {
    path: '/bind-info',
    name: 'bind-info',
    component: () => import('../pages/document/binding/binding-info.vue'),
  },
  {
    path: '/meeting-detail',
    name: 'meeting-detail',
    component: () => import('../pages/meeting/meeting-detail.vue'),
  },
  {
    path: '/appoint-reocrds',
    name: 'appoint-reocrds',
    component: () => import('../pages/appoint-records/appoint-reocrds.vue'),
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
  {
    path: '/law-view',
    name: 'law-view',
    component: () => import('../pages/lawyer-interview/law-view.vue'),
  },
  {
    path: '/meeting-record',
    name: 'meeting-record',
    component: () => import('../pages/meeting/meeting-record.vue'),
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
  {
    path: '/meeting-approval',
    name: 'meeting-approval',
    component: () => import('../pages/meeting/meeting-approval.vue'),
  },
  {
    path: '/live-detection-view',
    name: 'live-detection-view',
    component: () => import('../pages/components/live-detection-view.vue'),
  },
  //服刑人员信息
  {
    path: '/familyList',
    name: 'familyList',
    component: () => import('../pages/prisonOpen/familyOpen/familyList.vue'),
  },
  {
    path: '/socialList',
    name: 'socialList',
    component: () => import('../pages/prisonOpen/socialOpen/socialList.vue'),
  },
  {
    path: '/socialDetail',
    name: 'socialDetail',
    component: () => import('../pages/prisonOpen/socialOpen/socialDetail.vue'),
  },
  {
    path: '/letterList',
    name: 'letterList',
    component: () => import('../pages/forPeople/letter/letterList.vue'),
  },
  {
    path: '/addLetter',
    name: 'addLetter',
    component: () => import('../pages/forPeople/letter/addLetter.vue'),
  },
  {
    path: '/letterDetail',
    name: 'letterDetail',
    component: () => import('../pages/forPeople/letter/letterDetail.vue'),
  },
  {
    path: '/changePass',
    name: 'changePass',
    component: () => import('../components/changePass.vue'),
  },
  {
    path: '/arraigneHandling',
    name: 'arraigneHandling',
    component: () => import('../pages/arraigned-handling/arraigneHandling.vue'),
  },
  {
    path: '/courtTrial',
    name: 'courtTrial',
    component: () => import('../pages/court-trial/courtTrial.vue'),
  },
  {
    path: '/specialMetting',
    name: 'specialMetting',
    component: () => import('../pages/specialMetting/specialMetting.vue'),
  },
  {
    //须知
    path: '/notice',
    name: 'notice',
    component: () => import('../pages/components/notice.vue'),
  },
  {
    path: '/moneyList',
    name: 'moneyList',
    component: () => import('../pages/sendMoney/moneyList.vue'),
  },
  {
    path: '/addMoney',
    name: 'addMoney',
    component: () => import('../pages/sendMoney/addMoney.vue'),
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import('../pages/sendMoney/moneyDetail.vue'),
  },
  {
    path: '/recordList',
    name: 'recordList',
    component: () => import('../pages/publicRecords/recordList.vue'),
  },
  {
    path: '/downApp',
    name: 'downApp',
    component: () => import('../pages/meeting/downloadApp.vue'),
  },
  // 协议
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../pages/components/agreement.vue'),
  },
  // 人员选择
  {
    path: '/meetingCriminal',
    name: 'meetingCriminal',
    component: () => import('../pages/meeting/meeting-criminal.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 如果目标路由和当前路由相同，阻止导航
  if (to.name === from.name) {
    console.log('目标路由与当前路由相同，阻止跳转');
    next(false); // 阻止导航
  } else {
    next(); // 继续导航
  }
});

export default router;
